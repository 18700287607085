import {Alert, Snackbar} from "@mui/material";

export const Notification = ({handleClose, message, variant}) => (
    <Snackbar
        open={Boolean(message)} anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={variant} sx={{width: '100%'}}>
            {message}
        </Alert>
    </Snackbar>
);
