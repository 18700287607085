import {Form, Formik} from "formik";
import {Button, Grid} from "@mui/material";
import classes from '../../main.module.css';
import MuiDialogActions from "@mui/material/DialogActions";
import {InputField} from "../../../../common/Formik/InputField";
import {MaskedInputField} from "../../../../common/Formik/MaskedInputField";

export const StatusFrom = ({initial, handleCloseCallback, handleSubmitForm}) => (
    <Formik
        initialValues={initial}
        onSubmit={handleSubmitForm}
    >
        {({dirty}) => (
            <Form>
                <Grid container spacing={3} direction="row">
                    <Grid item className={classes.formStatusTitle}>Sender's bank details</Grid>
                    <Grid item xs={12}>
                        <InputField
                            size="small"
                            name="senderDetails.senderName"
                            label="Sender name"
                            required={dirty}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputField
                            size="small"
                            name="senderDetails.senderBank"
                            label="Sender`s bank"
                            required={dirty}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputField
                            size="small"
                            name="senderDetails.senderBankAccountNumber"
                            label="Sender`s bank account number"
                            required={dirty}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputField
                            size="small"
                            name="senderDetails.senderPhoneNumber"
                            label="Sender's telephone number"
                            required={dirty}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputField
                            size="small"
                            name="senderDetails.senderBankTransactionId"
                            label="Bank transaction id"
                            required={dirty}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputField
                            InputProps={{
                                inputComponent: MaskedInputField,
                                inputProps: {
                                    mask: [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/],
                                    guide: false
                                },
                            }}
                            size="small"
                            name="senderDetails.paymentDate"
                            label="Payment date (dd.mm.yyyy)"
                            required={dirty}
                        />
                    </Grid>
                </Grid>
                <MuiDialogActions style={{marginTop: "20px"}}>
                    <Button onClick={handleCloseCallback} color="primary">
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                        Send
                    </Button>
                </MuiDialogActions>
            </Form>
        )}
    </Formik>
)
