const SERVICE_API = process.env.REACT_APP_SERVICE_API || 'http://localhost:3003/api';
const baseURL = SERVICE_API;
const transactionsUrl = '/admin/transactions';
const finalizeUrl = '/admin/payment/finalize';
const bankDetailsUrl = '/admin/recipient/bank-details';
const miscUrl = '/assets';

const cbAfterFetch = async (response, link) => {
  if (response.status === 200) {
    const res = await response.json();
    return res;
  } else {
    const res = await response.json();
    throw new Error(res.message || `${link} error.`);
  }
};

const PostHeaders = {
  'Content-type': 'application/json',
  accept: 'application/json',
};

const requestGetTryBody = async (link) => {
  const response = await fetch(link, {
    method: 'get',
  });
  return cbAfterFetch(response, link);
};

export const getTransactions = (data) => {
  return requestGetTryBody(baseURL + transactionsUrl + `?${new URLSearchParams(data)}`);
};

export const getImage = async (id) => {
  const response = await fetch(baseURL + miscUrl + `/${id}`, {
    method: 'get',
  });
  if (response.status === 200) {
    const blob = await response.blob();
    return blob;
  } else {
    throw new Error(`error load img`);
  }
};

export const paymentFinalPost = async (data) => {
  const response = await fetch(baseURL + finalizeUrl, {
    method: 'post',
    headers: PostHeaders,
    body: JSON.stringify(data),
  });
  return cbAfterFetch(response, baseURL + finalizeUrl);
};

export const getBankDetails = () => {
  return requestGetTryBody(baseURL + bankDetailsUrl);
};

export const bankDetailsPost = async (data) => {
  const response = await fetch(baseURL + bankDetailsUrl, {
    method: 'post',
    headers: PostHeaders,
    body: JSON.stringify(data),
  });
  return cbAfterFetch(response, baseURL + bankDetailsUrl);
};
