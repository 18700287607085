import {
    Button,
    Grid,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import classes from '../main.module.css';
import {useState} from "react";
import {Statuses} from "../constants";
import {Form, Formik} from "formik";
import {InputField} from "../../../common/Formik/InputField";

export const Filters = ({submitFilterChange}) => {
    const [status, setStatus] = useState([]);

    const handleStatusChange = (props) => {
        const { target: { value } } = props;
        setStatus(typeof value === 'string' ? value.split(',') : value);
    };
    const handleSubmit = (data) => {
        submitFilterChange({
            ...data,
            status,
        });
    };

    return (
        <Formik
            initialValues={{}}
            onSubmit={handleSubmit}
        >
            {({ resetForm }) => {
                const handleReset = () => {
                    setStatus([]);
                    resetForm({});
                    submitFilterChange();
                };
                return (
                <Form>
                    <Typography variant="h5">Main filters</Typography>
                    <Grid container spacing={3} alignItems="end">
                        <Grid item md={3}>
                            <InputField
                                label="Payment ID"
                                name="paymentId"
                                variant="standard"
                                size="small"
                                className={classes.fullWidth}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <InputField
                                label="Sender name"
                                name="senderName"
                                variant="standard"
                                size="small"
                                className={classes.fullWidth}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <InputField
                                label="Sender's email"
                                name="customerEmail"
                                variant="standard"
                                InputProps={{type: 'email'}}
                                size="small"
                                className={classes.fullWidth}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <InputField
                                label="Sender’s bank"
                                name="senderBank"
                                variant="standard"
                                size="small"
                                className={classes.fullWidth}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <InputField
                                label="Sender’s bank account number"
                                name="senderBankAccountNumber"
                                variant="standard"
                                size="small"
                                className={classes.fullWidth}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <InputField
                                label="Sender's telephone number"
                                name="senderPhoneNumber"
                                variant="standard"
                                size="small"
                                className={classes.fullWidth}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <InputField
                                label="Bank transaction id"
                                name="senderBankTransactionId"
                                variant="standard"
                                size="small"
                                className={classes.fullWidth}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <Select
                                displayEmpty
                                size="large"
                                multiple
                                onChange={handleStatusChange}
                                value={status}
                                variant="standard"
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <Typography style={{ opacity: 0.7}}>Status</Typography>;
                                    }

                                    return selected.join(', ');
                                }}
                                className={classes.fullWidth}
                            >
                                {Statuses.map((name) => (
                                    <MenuItem
                                        key={name}
                                        value={name}
                                    >
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.actionWrap}>
                        <Grid item>
                            <Button color="primary" type="submit" variant="contained">
                                Apply
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleReset} variant="outlined">
                                Reset
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}}
        </Formik>
    )
}
