import {Dialog, IconButton, Button, Grid} from "@mui/material";
import {InputField} from "../../../common/Formik/InputField";
import MuiDialogActions from "@mui/material/DialogActions";
import CloseIcon from '@mui/icons-material/Close';
import classes from '../main.module.css';
import {Form, Formik} from 'formik';
import {useMutation, useQuery} from "react-query";
import {bankDetailsPost, getBankDetails} from "../../../common/requests/requests";
import MuiDialogTitle from "@mui/material/DialogTitle";
import {ConfirmModal} from "./ConfirmModal";
import React, {useState} from "react";
import Loader from "../../../common/Loader/Loader";
import {Notification} from "../../../common/Notification/Notification";


export const EditModal = ({
      handleCloseEditModal,
      isOpen,
  }) => {
    const [confirm, setConfirm] = useState(false);
    const [notification, setNotification] = useState({});

    const {data: bankInfo, loading,} = useQuery(
        'GET_BANK_DETAILS',
        getBankDetails,
        {
            errorPolicy: 'all',
            onError: handleCloseEditModal
        });

    const handleConfirmModal = () => {
        setConfirm(!confirm);
    }
    const {mutate, isLoading} = useMutation(bankDetailsPost, {
        onSuccess: () => {
            handleCloseEditModal();
            handleConfirmModal();
        },
        onError: (error) => {
            handleConfirmModal();
            setNotification({
                message: error.message || error.toString(),
                variant: 'error',
            });
        }
    });

    const handleMutate = (data) => {
        mutate(data);
    };


    if (loading) {
        return <Loader/>
    }


    return (
        <Dialog
            onClose={handleCloseEditModal}
            open={isOpen}
            fullWidth
            maxWidth="lg"
            scroll="body"
            style={{maxWidth: '580px', margin: 'auto'}}
            classes={{
                paper: classes.dialogTopPosition,
            }}
        >
            <MuiDialogTitle className={classes.confirmTitle}>
                Recipient's bank details
                <IconButton aria-label="close"
                            onClick={handleCloseEditModal}
                            className={classes.confirmCloseBtn}>
                    <CloseIcon/>
                </IconButton>
            </MuiDialogTitle>
            <Notification handleClose={() => setNotification({})} {...notification} />
            {loading || !bankInfo ? <Loader/> : (
                <Formik
                    initialValues={{
                        "bankName": bankInfo.bankName,
                        "accountHolderName": bankInfo.accountHolderName,
                        "accountNumber": bankInfo.accountNumber,
                        "iban": bankInfo.iban,
                        "recipientReference": bankInfo.recipientReference,
                    }}
                    onSubmit={handleConfirmModal}
                >
                    <Form style={{marginTop: '20px'}}>
                        <Grid container spacing={3} direction="row">
                            <Grid item xs={12}>
                                <InputField size="small" name="bankName" label="Bank name" required/>
                            </Grid>
                            <Grid item xs={12}>
                                <InputField size="small" name="accountHolderName" label="Account holder name" required/>
                            </Grid>
                            <Grid item xs={12}>
                                <InputField size="small" name="accountNumber" label="Account number" required/>
                            </Grid>
                            <Grid item xs={12}>
                                <InputField size="small" name="iban" label="IBAN" required/>
                            </Grid>
                            <Grid item xs={12}>
                                <InputField size="small" name="recipientReference" label="Recipient reference" required/>
                            </Grid>
                        </Grid>
                        <MuiDialogActions style={{marginTop: "30px"}}>
                            <Button onClick={handleCloseEditModal} variant="primary">
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Submit
                            </Button>
                        </MuiDialogActions>
                        {confirm && (<ConfirmModal handleCloseConfirm={handleConfirmModal} onMutate={handleMutate} isOpen={confirm} isLoading={isLoading} />)}
                    </Form>
                </Formik>
            )}

        </Dialog>
    );
};
