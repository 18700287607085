import React, { useMemo, useState } from 'react';
import { Link } from '@mui/material';
import classes from '../pages/main/main.module.css';

function truncateText(text, limit, symbolsToAdd = '...') {
  if (text.length > limit) {
    return `${text.substring(0, limit - symbolsToAdd.length)}${symbolsToAdd}`;
  }

  return text;
}

export const TruncateText = (props) => {
  const { text, limit = 500, showMoreLabel = "More", hideMoreLabel = "Hide" } = props;
  const showToggleLabel = text.length > limit;
  const shortText = useMemo(() => truncateText(text, limit), [text, limit]);

  const [isFullTextVisible, setIsFullTextVisible] = useState(false);

  const toggleText = () => {
    setIsFullTextVisible(!isFullTextVisible);
  };

  return (
    <>
      {isFullTextVisible ? text : shortText}
      {showToggleLabel && (
        <Link className={classes.toggleButton} onClick={toggleText} component="button" variant="body2">
          {isFullTextVisible ? hideMoreLabel : showMoreLabel}
        </Link>
      )}
    </>
  );
};
