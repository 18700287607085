import React from 'react';
import {Button, Dialog, IconButton} from "@mui/material";
import MuiDialogTitle from "@mui/material/DialogTitle";
import CloseIcon from '@mui/icons-material/Close';
import classes from '../../main.module.css';
import {useMutation} from "react-query";
import {paymentFinalPost} from "../../../../common/requests/requests";
import {useState} from "react";
import {Notification} from "../../../../common/Notification/Notification";
import Loader from "../../../../common/Loader/Loader";
import {StatusFrom} from "./StatusFrom";
import MuiDialogActions from "@mui/material/DialogActions";

const initial = {
    senderDetails: {
        paymentDate: "",
        senderBank: "",
        senderBankAccountNumber: "",
        senderName: "",
        senderPhoneNumber: "",
        senderBankTransactionId: "",
    }
}

export const StatusChangeModal = ({
      handleCloseCallback,
      isOpen,
      paymentId,
      status,
      actualStatus,
      updateList,
    }) => {
    const [notification, setNotification] = useState({});
    const {mutate, isLoading} = useMutation(paymentFinalPost, {
        onSuccess: () => {
            handleCloseCallback();
            setTimeout(updateList, 1000);
        },
        onError: (error) => {
            setNotification({
                message: error.message || error.toString(),
                variant: 'error',
            });
        }
    });

    const handleSubmitForm = (data) => {
        if (JSON.stringify(initial) !== JSON.stringify(data)) {
            if (/^([0]?[1-9]|[1|2][0-9]|[3][0|1])[.-]([0]?[1-9]|[1][0-2])[.-]([2][0][0-9]{2})$/.test(data.senderDetails.paymentDate)) {
                mutate({
                    paymentId,
                    status,
                    ...data
                })
            } else {
                setNotification({
                    message: "Invalid date",
                    variant: 'error',
                });
            }
        } else {
            mutate({
                paymentId,
                status,
            })
        }
    };

    const handleFinalizeStatus = () => {
        mutate({
            paymentId,
            status,
        })
    };

    return (
        <Dialog onClose={handleCloseCallback} open={isOpen} fullWidth
                maxWidth="lg"
                scroll="body"
                style={{maxWidth: '580px', margin: 'auto'}} classes={{
            paper: classes.dialogTopPosition,
        }}>
            <MuiDialogTitle style={{paddingLeft: '0'}}>
                Confirm status change to {status}
                <IconButton aria-label="close" onClick={handleCloseCallback} className={classes.confirmCloseBtn}>
                    <CloseIcon/>
                </IconButton>
            </MuiDialogTitle>
            <Notification handleClose={() => setNotification({})} {...notification} />
            {isLoading ? <Loader/> : (
                <>
                    {actualStatus === "CREATED" && status === "APPROVED" ? (
                        <StatusFrom
                            initial={initial}
                            handleSubmitForm={handleSubmitForm}
                            handleCloseCallback={handleCloseCallback}
                        />
                    ) : (
                        <MuiDialogActions style={{marginTop: "20px"}}>
                            <Button onClick={handleCloseCallback} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleFinalizeStatus} variant="contained" color="primary">
                                Send
                            </Button>
                        </MuiDialogActions>
                    )}
                </>
            )}
        </Dialog>
    );
}
