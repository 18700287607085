import React, {isValidElement} from 'react';

import {TextField} from "@mui/material";
import { useField} from 'formik';
import classes from '../../pages/main/main.module.css';

function ErrorText({
       children,
   }) {

    return isValidElement(children) || typeof children === 'string' ? (
        <div className={classes.errorWrapper}>{children}</div>
    ) : null;
}

export const InputField = ({
         fullWidth = true,
         name = '',
         helperText,
         ...rest
     }) => {
    const [field, {touched, error}] = useField(name);

    const value = field.value || '';

    return (
        <>
            <TextField
                error={touched && !!error}
                fullWidth={fullWidth}
                {...(error === helperText ? {} : {helperText})}
                {...field}
                value={value}
                {...rest}
            />

            {touched && (
                <>
                    {Array.isArray(error) ? (
                        error?.map((i, k) => (
                            <ErrorText key={`${i}-${k}`} absolute>
                                {i}
                            </ErrorText>
                        ))
                    ) : (
                        <ErrorText absolute>{error}</ErrorText>
                    )}
                </>
            )}
        </>
    );
};
