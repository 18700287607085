import {filterMetaField, initialPaging, multipleSorting, pageable} from "./constants";
import React, {useCallback, useRef, useState} from "react";
import {Table} from "./components/Table";
import classes from './main.module.css';
import {useKeycloak} from '@react-keycloak/web'
import {Button, Grid, Paper} from "@mui/material";
import {Filters} from "./components/Filters";
import {StatusChangeModal} from "./components/StatusChangeModal/StatusChangeModal";
import {useQuery} from "react-query";
import {getTransactions} from "../../common/requests/requests";
import {EditModal} from "./components/EditModal";
import Loader from "../../common/Loader/Loader";
import {ImageModal} from "./components/ImageModal";

const defaultVariables = {
    limit: initialPaging.take,
    offset: initialPaging.skip,
};

const Main = () => {
    const [paging, setPaging] = useState(initialPaging);
    const filterSearchValues = useRef(defaultVariables);
    const {keycloak} = useKeycloak()

    // action cell
    const [isStatusOpen, setStatusOpen] = useState(false);
    const [isEditOpen, setEditOpen] = useState(false);
    const [isImageOpen, setImageOpen] = useState(false);
    const [payment, setPayment] = useState(undefined);
    const [fileInfo, setFileInfo] = useState(undefined);

    const {data, loading, error, refetch} = useQuery(
        'GET_TRANSACTION_LIST',
        () => getTransactions(filterSearchValues.current),
        {
            errorPolicy: 'all',
        });

    const {data: transactions, count = 0} = data || [];

    const handlePageChange = useCallback(
        (e) => {
            const {skip, take} = e.page;
            const calcOffset = skip >= 10000 ? 10000 - take : skip;
            filterSearchValues.current = {
                ...filterSearchValues.current,
                offset: calcOffset,
                limit: take,
            };
            refetch();
            setPaging({skip, take});
        },
        [refetch]
    );

    const submitFilterChange = (data) => {
        if (!data) {
            setPaging(initialPaging);
            filterSearchValues.current = defaultVariables;
        } else {
            const correctedFields = Object.entries(data).reduce((newObj, [key, val]) => {
                if (val.length > 0) {
                    return {
                        ...newObj,
                        [filterMetaField[key]]: encodeURIComponent(val),
                    }
                }
                return newObj;
            }, {});
            filterSearchValues.current = {
                ...correctedFields,
                offset: 0,
                limit: paging.take,
            };
        }
        refetch();
    }

    const handleOpenImageModal = useCallback(
        (data) => () => {
            setFileInfo(data);
            setImageOpen(true);
        }, []
    );

    const handleOpenStatusModal = useCallback(
        (data) => () => {
            setPayment(data);
            setStatusOpen(true);
        }, []
    );

    const handleEditModal = () => {
        setEditOpen(!isEditOpen);
    };

    const handleCloseImageModal = () => {
        setImageOpen(false);
        setFileInfo(undefined);
    };

    const handleCloseStatusModal = () => {
        setStatusOpen(false);
        setPayment(undefined);
    };

    const updateList = useCallback(() => {
        filterSearchValues.current = {
            ...filterSearchValues.current,
            offset: paging.skip,
            limit: paging.take,
        };
        refetch();
    }, [paging.skip, paging.take, filterSearchValues, refetch]);

    return (
        <div className={classes.main}>
            <Grid container>
                <Button
                    onClick={handleEditModal}
                    style={{marginBottom: "20px"}}
                    variant="contained"
                >
                    Change Recipient's details
                </Button>
                <Button
                    onClick={() => keycloak.logout()}
                    style={{marginLeft: 'auto', marginBottom: "20px"}}
                    variant="outlined"
                >
                    Logout
                </Button>
            </Grid>
            <Paper className={classes.searchPanel}>
                <Filters submitFilterChange={submitFilterChange}/>
            </Paper>
            {error?.message ? <div className={classes.errorWrapper}>{error.message}</div> : null}
            {loading ? (
                <Loader/>
            ) : (
                <Table
                    loading={false}
                    multiple={multipleSorting}
                    handlePageChange={handlePageChange}
                    handleStatusModal={handleOpenStatusModal}
                    handleOpenImageModal={handleOpenImageModal}
                    pageable={pageable}
                    skip={paging.skip}
                    take={paging.take}
                    data={transactions}
                    count={count}
                />)}
            {isImageOpen && (
                <ImageModal
                    handleClose={handleCloseImageModal}
                    isOpen={isImageOpen}
                    fileInfo={fileInfo}
                />
            )}
            {isStatusOpen && (
                <StatusChangeModal
                    handleCloseCallback={handleCloseStatusModal}
                    isOpen={isStatusOpen}
                    paymentId={payment?.paymentId}
                    status={payment?.status}
                    actualStatus={payment?.actualStatus}
                    updateList={updateList}
                />
            )}
            {isEditOpen && (
                <EditModal
                    handleCloseEditModal={handleEditModal}
                    isOpen={isEditOpen}
                />
            )}
        </div>
    )
}

export default Main;
