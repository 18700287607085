import React, {useMemo, useState} from 'react';

import {Box, IconButton, Menu, MenuItem} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const PaymentStatuses = ({
        handleStatusModal,
        dataItem,
    }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const isActionsMenuOpen = Boolean(anchorEl);

    const actionsData = useMemo(
        () => [
            {
                name: "APPROVED",
                handleActionClick: handleStatusModal({paymentId: dataItem?.paymentId, status: 'APPROVED', actualStatus: dataItem.status})
            },
            {
                name: (
                    <Box display="flex" color="#EB5757" alignItems="center">
                        <Box mr="10px">DECLINED</Box>
                    </Box>
                ),
                handleActionClick: handleStatusModal({paymentId: dataItem?.paymentId, status: 'DECLINED', actualStatus: dataItem.status}),
            },
        ],
        [dataItem, handleStatusModal]
    );
    const isShowActions = dataItem.status === "SUBMITTED" || dataItem.status === "CREATED";

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <td>
            {isShowActions ? (
                <>
                    <IconButton aria-label="more" aria-controls="actions-menu" aria-haspopup="true"
                                onClick={handleClick}>
                        <MoreVertIcon/>
                    </IconButton>
                    <Menu anchorEl={anchorEl} id="actions-menu" open={isActionsMenuOpen} onClose={handleClose}>
                        {actionsData.map(({handleActionClick, name, disabled = false}) =>
                            <MenuItem key={name.toString()} disabled={disabled} onClick={handleActionClick}>
                                {name}
                            </MenuItem>
                        )}
                    </Menu>
                </>
            ) : null}
        </td>
    );
};
