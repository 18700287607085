import {Dialog, IconButton, Button} from "@mui/material";
import MuiDialogActions from "@mui/material/DialogActions";
import MuiDialogTitle from "@mui/material/DialogTitle";
import CloseIcon from '@mui/icons-material/Close';
import classes from '../main.module.css';
import {useFormikContext} from "formik";

export const ConfirmModal = ({
  handleCloseConfirm,
  isOpen,
 isLoading,
 onMutate,
  }) => {
    const { values } = useFormikContext();
    return (
        <Dialog onClose={handleCloseConfirm} open={isOpen} maxWidth="md">
            <MuiDialogTitle className={classes.confirmTitle}>
                Confirm changes
                <IconButton aria-label="close" onClick={handleCloseConfirm} className={classes.confirmCloseBtn}>
                    <CloseIcon/>
                </IconButton>
            </MuiDialogTitle>
            <MuiDialogActions>
                <Button onClick={handleCloseConfirm} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => onMutate(values)} variant="contained" color="primary" disabled={isLoading}>
                    Submit
                </Button>
            </MuiDialogActions>
        </Dialog>
    );
};
