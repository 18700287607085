import {
  AmountCell,
  BankCell,
  DateGridCell, LinksCell,
  SenderCell,
  StatusCell,
  TextCell
} from "../components/GridCells";

export const gridColumns = [
  {
    id: 1,
    field: 'paymentId',
    title: "Payment ID",
    sortable: false,
    filterable: true,
    cell: TextCell,
    visible: true,
    width: 220,
  },
  {
    id: 2,
    field: 'bankName',
    title: "Bank name",
    sortable: false,
    filterable: true,
    cell: BankCell,
    visible: true,
    width: 220,
  },
  {
    id: 3,
    field: 'accountHolderName',
    title: "Account holder name",
    sortable: false,
    filterable: true,
    cell: BankCell,
    visible: true,
    width: 220,
  },
  {
    id: 4,
    field: 'iban',
    title: "IBAN",
    sortable: false,
    filterable: true,
    cell: BankCell,
    visible: true,
    width: 220,
  },
  {
    id: 14,
    field: 'accountNumber',
    title: "Account number",
    sortable: false,
    filterable: true,
    cell: BankCell,
    visible: true,
    width: 220,
  },
  {
    id: 5,
    field: 'recipientReference',
    title: "Recipient reference",
    sortable: false,
    filterable: true,
    cell: BankCell,
    visible: true,
    width: 220,
  },
  {
    id: 13,
    field: 'customerEmail',
    title: "Sender's email",
    sortable: false,
    filterable: true,
    cell: TextCell,
    visible: true,
    width: 220,
  },
  {
    id: 6,
    field: 'senderName',
    title: "Sender name",
    sortable: false,
    filterable: true,
    cell: SenderCell,
    visible: true,
    width: 220,
  },
  {
    id: 7,
    field: 'senderBank',
    title: "Sender’s bank",
    sortable: false,
    filterable: true,
    cell: SenderCell,
    visible: true,
    width: 220,
  },
  {
    id: 8,
    field: 'senderBankAccountNumber',
    title: "Sender’s bank account number",
    sortable: false,
    filterable: true,
    cell: SenderCell,
    visible: true,
    width: 220,
  },
  {
    id: 16,
    field: 'senderPhoneNumber',
    title: "Sender's telephone number",
    sortable: false,
    filterable: true,
    cell: SenderCell,
    visible: true,
    width: 220,
  },
  {
    id: 15,
    field: 'senderBankTransactionId',
    title: "Bank transaction id",
    sortable: false,
    filterable: true,
    cell: SenderCell,
    visible: true,
    width: 220,
  },
  {
    id: 9,
    field: 'paymentDate',
    title: "Payment date",
    sortable: false,
    filterable: true,
    cell: DateGridCell,
    visible: true,
    width: 220,
  },
  {
    id: 10,
    field: 'files',
    title: "Payment proof",
    sortable: false,
    filterable: true,
    cell: LinksCell,
    visible: true,
    width: 220,
  },
  {
    id: 11,
    field: 'amount',
    title: "Amount",
    sortable: false,
    filterable: true,
    cell: AmountCell,
    visible: true,
    width: 220,
  },
  {
    id: 12,
    field: 'status',
    title: "Status",
    sortable: false,
    filterable: true,
    cell: StatusCell,
    visible: true,
    width: 220,
  },
];
