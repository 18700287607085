import React from 'react';
import { createPortal } from 'react-dom';

import './loader.css';

function Loader({ show = true, fullScreen, title }) {
  if (show && fullScreen) {
    return createPortal(
      (
        <div className="waiting-modal">
          {title && (<h2>{title}</h2>)}
          <div className="k-loading-image k-loading-image__handled"/>
        </div>
      ),
      document.body
    );
  }

  if (show) {
    return (
      <div className="k-loading-mask">
        <span className="k-loading-text">Loading</span>
        <div className="k-loading-image"/>
        <div className="k-loading-color"/>
      </div>
    );
  }

  return null;
}

export default Loader;
