import { Route, Routes } from "react-router";
import { useKeycloak } from "@react-keycloak/web"
import { Layout } from "./common/layout/Layout";

import Main from './pages/main/Main';

export default function ApplicationRoutes() {
  const { initialized} = useKeycloak();

  if (!initialized) return null;


  return (
    <Routes>
      <Route
        path="*"
        element={
          <Layout>
            <Main />
          </Layout>
        }
      />
    </Routes>
  );
}
