import React from 'react';
import MaskedInput from 'react-text-mask';

export const MaskedInputField = React.forwardRef((props, inputRef) => {
  const maskedInputRef = (ref) => {
    inputRef(ref ? ref.inputElement : null);
  };

  return <MaskedInput {...props} ref={maskedInputRef} />;
})
