import React, {memo, useCallback} from 'react';
import {
    Grid,
    GridColumn,
    GridNoRecords,
} from '@progress/kendo-react-grid';
import {gridColumns} from "../constants/gridColumns";
import {PaymentStatuses,} from "./PaymentActions";
import {LinksCell} from "./GridCells";

const ActionCell = ({
        handleStatusModal,
    }) => (props) => (
    <PaymentStatuses
        handleStatusModal={handleStatusModal}
        {...props}
    />
);

export const Table = memo(
    ({
         handleStatusModal,
         handleOpenImageModal,
         handlePageChange,
         multiple,
         pageable,
         skip,
         sort,
         take,
         data,
         count = 0,
     }) => {
        const GridColumns = gridColumns
            .map(({id, ...props}) => {
                if (props.field === 'files') {
                    return <GridColumn key={id} {...props} cell={(cellInfo) => <LinksCell handleOpenImageModal={handleOpenImageModal} {...cellInfo}/>}/>
                }
                return <GridColumn key={id} {...props} />
            });



        const rowRender = useCallback((trElement) => {
            return React.cloneElement(
                trElement,
                trElement.props,
                trElement.props.children
            );
        }, []);

        return (
            <div style={{overflow: 'auto'}}>
                <Grid
                    className="k-grid_size_small"
                    data={data}
                    onPageChange={handlePageChange}
                    pageable={pageable}
                    selectedField="selected"
                    skip={skip}
                    sort={sort}
                    sortable={{mode: multiple ? 'multiple' : 'single'}}
                    take={take}
                    total={count}
                    expandField="expanded"
                    rowRender={rowRender}
                >
                    <GridNoRecords>{"There is no data available"}</GridNoRecords>
                    {GridColumns}
                    <GridColumn
                        cell={ActionCell({handleStatusModal})}
                        filterable={false}
                        sortable={false}
                        title="Actions"
                        width={60}
                    />
                </Grid>
            </div>
        );
    }
);
