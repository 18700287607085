import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@progress/kendo-theme-material/dist/all.css";
import "./App.css";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";
import ApplicationRoutes from "./router";
import CssBaseline from "@mui/material/CssBaseline";
import { QueryClientProvider } from "./context/QueryClientContext";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";

const App = () => {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: "login-required",
        checkLoginIframe: true,
        autoRefreshToken: true,
      }}
    >
      <QueryClientProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <CssBaseline />
            <ApplicationRoutes />
          </BrowserRouter>
        </ThemeProvider>
      </QueryClientProvider>
    </ReactKeycloakProvider>
  );
};

createRoot(document.getElementById("root")).render(<App />);
