import { deepOrange, lightGreen, yellow, grey} from "@mui/material/colors";

export const dateTimeFormat = 'dd.MM.y HH:mm:ss';
export const timeFormat = 'HH:mm:ss';
export const datePlaceholder = 'DD/MM/YY';


export const itemsPerPage = 50;

export const initialPaging = {
  skip: 0,
  take: itemsPerPage,
};

export const pagingSettings = {
  buttonCount: 5,
  info: true,
  pageSizes: [50, 100],
};


export const filterMetaField = {
  paymentId: 'paymentId',
  customerEmail: 'customerEmail',
  senderName: 'senderDetails.senderName',
  senderBank: 'senderDetails.senderBank',
  senderBankAccountNumber: 'senderDetails.senderBankAccountNumber',
  senderPhoneNumber: 'senderDetails.senderPhoneNumber',
  senderBankTransactionId: 'senderDetails.senderBankTransactionId',
  offset: 'offset',
  limit: 'limit',
  status: 'status',
};

// Transaction list datatable
export const pageable = {
  ...pagingSettings,
  pageSizes: [50, 100, 500],
};

export const multipleSorting = false;

export const Statuses = ["CREATED", "SUBMITTED", "DECLINED", "APPROVED"];

// Status Colors
export const statusColors = {
  SUBMITTED: yellow[100],
  CREATED: grey[300],
  APPROVED: lightGreen[100],
  DECLINED: deepOrange[100],
};
