import {Dialog, IconButton} from "@mui/material";
import MuiDialogTitle from "@mui/material/DialogTitle";
import CloseIcon from '@mui/icons-material/Close';
import classes from '../main.module.css';
import {useQuery} from "react-query";
import {getImage} from "../../../common/requests/requests";
import Loader from "../../../common/Loader/Loader";
import React from "react";

export const ImageModal = ({
 handleClose,
 isOpen,
 fileInfo
 }) => {
    const {data, loading, error,} = useQuery(
        'GET_IMAGE',
        () => getImage(fileInfo.id),
        {errorPolicy: 'all'}
    );
    return (
        <Dialog onClose={handleClose} open={isOpen} maxWidth="md">
            <MuiDialogTitle className={classes.confirmTitle}>
                {fileInfo.filename}
                <IconButton aria-label="close" onClick={handleClose} className={classes.confirmCloseBtn}>
                    <CloseIcon/>
                </IconButton>
            </MuiDialogTitle>
            <div className={classes.modalImage}>
                {error ?
                    <div className={classes.modalImageErrorText}>Failed to load image</div>
                    : (
                        <>
                            {loading || !data ? <Loader/> : <img src={URL.createObjectURL(data)} alt=""/>}
                        </>
                    )
                }
            </div>
        </Dialog>
    );
};
