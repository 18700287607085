import { statusColors} from "../constants";
import {TruncateText} from "../../../common/TruncateText";
import {Chip} from "@mui/material";
import classes from '../main.module.css';

export const StatusCell = ({ dataItem: {status} }) => {
  return (
    <td>
      <Chip label={status} title={status} size="small" style={{ backgroundColor: statusColors[status], display: 'flex' }} />
    </td>
  );
};

export const DateGridCell = ({ dataItem: {senderDetails}, field }) => {
    if (!senderDetails) return <td/>
    return (
        <td style={{textAlign: 'left'}}>{senderDetails[field]}</td>
    )
};

export const TextCell = ({ dataItem, field = '' }) => (
    <td>
      <TruncateText text={dataItem[field] || '—'} limit={52} />
    </td>
);

export const LinksCell = ({ dataItem: {senderDetails} = '', handleOpenImageModal }) => {
    return (
        <td style={{color: "#3f50b5"}}>
            {senderDetails?.files.map((fileInfo) => (
                <div
                    onClick={handleOpenImageModal(fileInfo)}
                    className={classes.linkFile}
                    key={fileInfo.id}
                >
                    {fileInfo.filename}
                </div>
            ))}
        </td>
    )
}

export const BankCell = ({ dataItem: {requisites}, field = ''  }) => {

    return (
    <td>
      <TruncateText text={requisites.bankDetails[field] || '—'} limit={52}/>
    </td>
)};

export const SenderCell = ({ dataItem: {senderDetails}, field = ''  }) => {
    if (!senderDetails) return <td/>
    return (
    <td>
      <TruncateText text={senderDetails[field] || '—'} limit={52}/>
    </td>
)};

export const AmountCell = ({ dataItem  }) => {
    return (
    <td>
      {dataItem.amount || '—'} {dataItem.currency}
    </td>
)};

